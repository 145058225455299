<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template
      v-if="isNewUser && isSidebarActive"
    >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Nuevo cliente
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="isSidebarActive = false"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nombre completo"
            rules="required"
          >
            <b-form-group
              label="Nombre completo"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.customerName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nombre del cliente"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.customerMail"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            name="Teléfono"
            rules="required"
          >
            <b-form-group
              label="Teléfono"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userData.customerPhone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loadingButton"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner
                v-if="loadingButton"
                small
              />
              <span v-else>Añadir</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :disabled="loadingButton"
              type="button"
              variant="outline-secondary"
              @click="isSidebarActive = false"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
    <template
      v-if="!isNewUser && isSidebarActive"
      #default="{ hide }"
    >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ficha de cliente
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nombre completo"
            rules="required"
          >
            <b-form-group
              label="Nombre completo"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userInfoData.customerName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Nombre del cliente"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userInfoData.customerMail"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            name="Teléfono"
            rules="required"
          >
            <b-form-group
              label="Teléfono"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userInfoData.customerPhone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loadingButton"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner
                v-if="loadingButton"
                small
              />
              <span v-else>Actualizar</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :disabled="loadingButton"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
    <b-toast id="example-toast">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-1">
          <b-img
            :src="require('@/assets/images/logo/logo.png')"
            class="mr-1"
            height="18"
            width="25"
            alt="Toast image"
          />
          <strong class="mr-auto">Vue Admin</strong>
          <small class="text-muted">11 mins ago</small>
        </div>
      </template>
      <span>Hello, world! This is a toast message. Hope you're doing well.. :)</span>
    </b-toast>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BToast, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { ref, computed } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BToast,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    isNewUser: {
      type: Boolean,
      required: true,
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loadingButton: false,
      data: {
        required,
        alphaNum,
        email,
        countries,
        isSidebarActive: false,
        loadingButton: false,
        userData: {},
        userInfoData: {},
        blankUserData: {
          customerName: '',
          customerMail: '',
          customerPhone: '',
        },
      },
    }
  },
  computed: {
    isSidebarActive: {
      get() {
        this.initAssignments()
        return this.isAddNewUserSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-user-sidebar-active', false)
        }
      },
    },
  },
  created() {
  },
  mounted() {

  },
  methods: {
    initAssignments() {
      if (!this.isNewUser) this.userInfoData = { ...this.userInfo }
      else this.userData = { ...this.blankUserData }
    },
    resetForm(context) {
      const { resetForm } = formValidation()
      return resetForm
    },
    refFormObserver(context) {
      const { refFormObserver } = formValidation()
      return refFormObserver(context)
    },
    getValidationState(context) {
      const { getValidationState } = formValidation()
      return getValidationState(context)
    },
    resetuserData() {
      this.userData = JSON.parse(JSON.stringify(this.blankUserData))
    },
    async onSubmit() {
      this.loadingButton = true
      if (this.isNewUser) {
        await store.dispatch('customers/addCustomers', this.userData)
          .then(() => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Clientes',
                icon: 'BellIcon',
                text: '🙋 Cliente creado',
                variant: 'success',
              },
            },
            {
              position: 'bottom-center',
            })
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Clientes',
                icon: 'BellIcon',
                text: '🙇‍♀️ Error al crear el cliente.',
                variant: 'error',
              },
            },
            {
              position: 'bottom-center',
            })
          })
      } else {
        await store.dispatch('customers/updateCustomers', this.userInfoData)
          .then(() => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Clientes',
                icon: 'BellIcon',
                text: '🙋 Cliente actualizado',
                variant: 'success',
              },
            },
            {
              position: 'bottom-center',
            })
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Clientes',
                icon: 'BellIcon',
                text: '🙇‍♀️ Error al actualizar cliente.',
                variant: 'error',
              },
            },
            {
              position: 'bottom-center',
            })
          })
      }
      this.loadingButton = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
